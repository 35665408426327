<template>
    <div class="overview-area ptb-100 pt-0">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">About Us</span>
                <h2>Amazing Business Solution Whatever Your Needs</h2>
                <p>Real innovations and a positive customer experience are the heart of successful communication. No fake products and services.</p>
            </div>

            <div class="overview-box">
                <div class="overview-content">
                    <div class="content">
                        <span class="sub-title">Digital Marketing</span>
                        <h2>Creative solutions, creative results</h2>
                        <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p>

                        <div class="features-text">
                            <h4><i class="flaticon-tick"></i> Core Development</h4>
                            <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                        </div>

                        <div class="features-text">
                            <h4><i class="flaticon-tick"></i> Define Your Choices</h4>
                            <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                        </div>
                    </div>
                </div>

                <div class="overview-image">
                    <div class="image">
                        <img src="../../assets/images/about/about-img1.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="overview-box">
                <div class="overview-image">
                    <div class="image">
                        <img src="../../assets/images/about/about-img2.png" alt="image">
                    </div>
                </div>
                
                <div class="overview-content">
                    <div class="content right-content">
                        <span class="sub-title">Design & Development</span>
                        <h2>Solving problems, building brands</h2>
                        <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p>

                        <ul class="features-list">
                            <li><span><i class='bx bx-check'></i> Recommender systems</span></li>
                            <li><span><i class='bx bx-check'></i> Demand prediction</span></li>
                            <li><span><i class='bx bx-check'></i> Omnichannel analytics</span></li>
                            <li><span><i class='bx bx-check'></i> Lead generation</span></li>
                            <li><span><i class='bx bx-check'></i> Dedicated Developers</span></li>
                            <li><span><i class='bx bx-check'></i> 24/7 Support</span></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="overview-box">
                <div class="overview-content">
                    <div class="content">
                        <span class="sub-title">Social Media Marketing</span>
                        <h2>We are the next generation of the advertising world</h2>
                        <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p>

                        <div class="features-text">
                            <h4><i class="flaticon-tick"></i> Core Development</h4>
                            <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                        </div>

                        <div class="features-text">
                            <h4><i class="flaticon-tick"></i> Define Your Choices</h4>
                            <p>No fake products and services. The customer is king, their lives and needs are the inspiration.</p>
                        </div>
                    </div>
                </div>

                <div class="overview-image">
                    <div class="image">
                        <img src="../../assets/images/about/about-img3.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="overview-box">
                <div class="overview-image">
                    <div class="image">
                        <img src="../../assets/images/about/about-img4.png" alt="image">
                    </div>
                </div>
                
                <div class="overview-content">
                    <div class="content right-content">
                        <span class="sub-title">SEO Consultancy</span>
                        <h2>We make smart, good-looking things</h2>
                        <p>We believe brand interaction is key in communication. Real innovations and a positive customer experience are the heart of successful communication.</p>

                        <ul class="features-list">
                            <li><span><i class='bx bx-check'></i> Recommender systems</span></li>
                            <li><span><i class='bx bx-check'></i> Demand prediction</span></li>
                            <li><span><i class='bx bx-check'></i> Omnichannel analytics</span></li>
                            <li><span><i class='bx bx-check'></i> Lead generation</span></li>
                            <li><span><i class='bx bx-check'></i> Dedicated Developers</span></li>
                            <li><span><i class='bx bx-check'></i> 24/7 Support</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AmazingBusinessSolution'
}
</script>