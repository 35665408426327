<template>
    <div class="how-its-work-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="how-its-work-content">
                        <span class="sub-title"><img src="../../assets/images/star-icon.png" alt="image"> Why Choose Us</span>
                        <h2>Outstanding Digital Experience</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                        <div class="inner-box">
                            <div class="single-item">
                                <div class="count-box">
                                    1
                                </div>
                                <h3>Best Performence</h3>
                                <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p>
                            </div>
                            <div class="single-item">
                                <div class="count-box">
                                    2
                                </div>
                                <h3>Dedicated Team Member</h3>
                                <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p>
                            </div>
                            <div class="single-item">
                                <div class="count-box">
                                    3
                                </div>
                                <h3>24/7 Support</h3>
                                <p>Dolor sit amet consectetur elit eiusmod tempor incidi dunt labore dolore magna aliqua enim.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="how-its-work-image" data-tilt>
                        <img src="../../assets/images/how-its-work.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'OutstandingDigitalExperience'
}
</script>