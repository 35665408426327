<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Service Details" />
        <ServicesDetailsContent />
        <MoreServices />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import ServicesDetailsContent from '../ServiceDetailsOne/ServiceDetailsContent'
import MoreServices from '../ServiceDetailsOne/MoreServices'
import Footer from '../Layout/Footer'

export default {
    name: 'ServiceDetailsPageOne',
    components: {
        Navbar,
        PageTitle,
        ServicesDetailsContent,
        MoreServices,
        Footer,
    }
}
</script>
