<template>
    <div class="funfacts-area bg-image pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-3 col-6 col-md-3">
                    <div class="single-funfacts-item">
                        <div class="icon">
                            <img src="../../assets/images/funfacts/funfacts1.png" alt="image">
                        </div>
                        <h3>10 Years</h3>
                        <p>On the market</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-3 col-6 col-md-3">
                    <div class="single-funfacts-item">
                        <div class="icon">
                            <img src="../../assets/images/funfacts/funfacts2.png" alt="image">
                        </div>
                        <h3>50+</h3>
                        <p>Team Members</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-3 col-6 col-md-3">
                    <div class="single-funfacts-item">
                        <div class="icon">
                            <img src="../../assets/images/funfacts/funfacts3.png" alt="image">
                        </div>
                        <h3>100%</h3>
                        <p>Satisfaction Rate</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-3 col-6 col-md-3">
                    <div class="single-funfacts-item">
                        <div class="icon">
                            <img src="../../assets/images/funfacts/funfacts4.png" alt="image">
                        </div>
                        <h3>90%</h3>
                        <p>Senior Scientist</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'FunfactsTwo'
}
</script>