<template>
    <div>
        <div class="main-banner">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-5 col-md-12">
                        <div class="main-banner-content">
                            <h1>Data Science Consulting Services</h1>
                            <p>Get professional & reliable research oriented solutions for Data Science and Machine Learning business needs. Enjoy stress free decesion making!</p>
                            <div class="btn-box">
                                <router-link to="/about-us-one" class="default-btn"><i class="flaticon-structure"></i>About Us<span></span></router-link>
                                <div 
                                    class="video-btn"
                                    v-on:click="isPopupMethod(isPopup)"
                                    style="cursor: pointer"
                                >
                                    <i class="flaticon-google-play"></i>
                                    Watch Video
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-12">
                        <div class="main-banner-animation-image">
                            <img src="../../assets/images/main-banner/banner-one/banner-one1.png" alt="image">
                            <img src="../../assets/images/main-banner/banner-one/banner-one2.png" alt="image">
                            <img src="../../assets/images/main-banner/banner-one/banner-one3.png" alt="image">
                            <img src="../../assets/images/main-banner/banner-one/banner-one4.png" alt="image">
                            <img src="../../assets/images/main-banner/banner-one/banner-one5.png" alt="image">
                            <img src="../../assets/images/main-banner/banner-one/banner-one6.png" alt="image">
                            <img src="../../assets/images/main-banner/banner-one/banner-one7.png" alt="image">
                            <img src="../../assets/images/main-banner/banner-one/banner-one8.png" alt="image">
                            <img src="../../assets/images/main-banner/banner-one/banner-one9.png" alt="image">
                            <img src="../../assets/images/main-banner/banner-one/banner-one10.png" alt="image">
                            <img src="../../assets/images/main-banner/banner-one/banner-one11.png" alt="image">
                            <img src="../../assets/images/main-banner/banner-one/banner-one12.png" alt="image">
                            <img src="../../assets/images/main-banner/banner-one/banner-one-main-pic.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div 
            class="popup-video show" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner',
    data (){
        return {
            isPopup: false
        }
    },
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
    },
}
</script>