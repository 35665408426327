<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Case Studies 3 Columns" />
        <CaseStudiesContent />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import CaseStudiesContent from '../CaseStudiesTwo/CaseStudiesContent'
import Footer from '../Layout/Footer'

export default {
    name: 'CaseStudiesPageTwo',
    components: {
        Navbar,
        PageTitle,
        CaseStudiesContent,
        Footer,
    }
}
</script>
