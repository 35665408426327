<template>
  <div class="profile-authentication-area ptb-100">
    <div class="container">
      <div class="row">
        <div v-if="isTeacher" class="col-lg-12 col-md-12">
          <div class="login-form">
            <b-alert :show="!!registerSuccess">{{ registerSuccess }}</b-alert>
            <form @submit.prevent.stop="onRegisterTeacher">
              <div class="form-group">
                <label>Актуальная информация</label>
                <input type="text" class="form-control" v-model="register.Information" required />
              </div>

              <div class="form-group">
                <label>Область/Регион</label>
                <input type="text" class="form-control" v-model="register.Region" required />
              </div>

              <div class="form-group">
                <label>Город</label>
                <input type="text" class="form-control" v-model="register.City" required />
              </div>

              <div class="form-group">
                <label>Школа (учебное заведение)</label>
                <input type="text" class="form-control" v-model="register.School" required />
              </div>

              <div class="form-group">
                <label>Должность</label>
                <input type="text" class="form-control" v-model="register.Position" />
              </div>

              <div class="form-group">
                <label>Фамилия</label>
                <input type="text" class="form-control" v-model="register.Lastname" required />
              </div>

              <div class="form-group">
                <label>Имя</label>
                <input type="text" class="form-control" v-model="register.Firstname" required />
              </div>

              <div class="form-group">
                <label>Отчество</label>
                <input type="text" class="form-control" v-model="register.Middlename" />
              </div>

              <div class="form-group">
                <label>E-mail</label>
                <input type="email" class="form-control" v-model="register.Email" required />
              </div>

              <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 col-sm-12 remember-me-wrap">
                  <p>
                    <input type="checkbox" id="test2" v-model="register.Agreement" required />
                    <label for="test2">Я ознакомлен с положением и даю согласие на обработку персональных
                      данных!</label>
                  </p>
                </div>

                <!-- <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                  <a href="#" class="lost-your-password">Lost your password?</a>
                </div> -->
              </div>

              <button type="submit" style="position: relative">
                <div v-if="isRegisterLoading" class="spinner-border text-white"
                  style="width: 20px; height: 20px; position: absolute; left: calc(50% - 10px); top: calc(50% - 10px)" />
                <span :class="{ invisible: isRegisterLoading }">
                  Регистрация
                </span>
              </button>
            </form>
          </div>
        </div>

        <div v-else class="col-lg-12 col-md-12">
          <div class="login-form">
            <b-alert :show="!!registerSuccess">{{ registerSuccess }}</b-alert>
            <form @submit.prevent.stop="onRegisterStudent">
              <div class="form-group">
                <label>Область/Регион</label>
                <input type="text" class="form-control" v-model="registerStudent.Region" :readonly="hasActiveRef" />
              </div>

              <div class="form-group">
                <label>Город</label>
                <input type="text" class="form-control" v-model="registerStudent.City" :readonly="hasActiveRef" />
              </div>

              <div class="form-group">
                <label>Школа (учебное заведение)</label>
                <input type="text" class="form-control" v-model="registerStudent.School" :readonly="hasActiveRef" />
              </div>

              <div class="form-group">
                <label>Класс</label>
                <input type="text" class="form-control" v-model="registerStudent.Position" />
              </div>

              <div class="form-group">
                <label>Фамилия</label>
                <input type="text" class="form-control" v-model="registerStudent.Lastname" required />
              </div>

              <div class="form-group">
                <label>Имя</label>
                <input type="text" class="form-control" v-model="registerStudent.Firstname" required />
              </div>

              <div class="form-group">
                <label>Отчество</label>
                <input type="text" class="form-control" v-model="registerStudent.Middlename" />
              </div>

              <div class="form-group">
                <label>E-mail</label>
                <input type="text" class="form-control" v-model="registerStudent.Email" required />
              </div>

              <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 col-sm-12 remember-me-wrap">
                  <p>
                    <input type="checkbox" id="test3" v-model="registerStudent.Agreement" required />
                    <label for="test3">Я ознакомлен с положением и даю согласие на обработку персональных
                      данных!</label>
                  </p>
                </div>
              </div>

              <button type="submit" style="position: relative">
                <div v-if="isRegisterLoading" class="spinner-border text-white"
                  style="width: 20px; height: 20px; position: absolute; left: calc(50% - 10px); top: calc(50% - 10px)" />
                <span :class="{ invisible: isRegisterLoading }">
                  Регистрация
                </span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "LoginRegisterContent",

  props: {
    isTeacher: Boolean
  },

  data() {
    return {
      registerError: null,
      isRegisterLoading: false,
      hasActiveRef: false,

      registerInitial: {
        Information: '',
        Region: '',
        City: '',
        School: '',
        Position: '',
        Lastname: '',
        Firstname: '',
        Middlename: '',
        Email: '',
        Agreement: '',
      },
      register: {
        Information: '',
        Region: '',
        City: '',
        School: '',
        Position: '',
        Lastname: '',
        Firstname: '',
        Middlename: '',
        Email: '',
        Agreement: '',
      },

      registerStudentInitial: {
        Information: '',
        Region: '',
        City: '',
        School: '',
        Position: '',
        Lastname: '',
        Firstname: '',
        Middlename: '',
        Email: '',
        Agreement: '',
      },
      registerStudent: {
        Region: '',
        City: '',
        School: '',
        Position: '',
        Lastname: '',
        Firstname: '',
        Middlename: '',
        Email: '',
        Agreement: '',
      },
    };
  },

  computed: {
    registerFormatted() {
      return {
        ...this.register,
        agreement: this.register.agreement ? 'on' : this.register.agreement
      }
    },
    registerStudentFormatted() {
      return {
        ...this.registerStudent,
        agreement: this.registerStudent.agreement ? 'on' : this.registerStudent.agreement
      }
    }
  },

  methods: {
    async onRegisterTeacher() {
      this.isRegisterLoading = true
      try {
        await axios
          .post(
            `https://api.dictant.qt2.ru/auth/register-teacher`,
            this.registerFormatted,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
          .then(async () => {
            this.registerError = null;
            this.registerSuccess = 'Письмо отправлено на Ваш E-mail';
            this.register = this.registerInitial;

            const loginForm = document.querySelector('.login-form');
            const rect = loginForm.getBoundingClientRect();
            const offset = window.scrollY + rect.top - 100; // 100 пикселей выше
            window.scrollTo({ top: offset, behavior: 'smooth' });
          })
          .catch((error) => {
            console.error(error)
          });
      } catch (error) {
        console.error(error);
      } finally {
        this.isRegisterLoading = false
      }
    },
    async onRegisterStudent() {
      this.isRegisterLoading = true
      try {
        await axios
          .post(
            `https://api.dictant.qt2.ru/auth/register`,
            this.registerStudentFormatted,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
          .then(async () => {
            this.registerError = null;
            this.registerSuccess = 'Письмо отправлено на Ваш E-mail';
            this.registerStudent = this.registerStudentInitial;

            const loginForm = document.querySelector('.login-form');
            const rect = loginForm.getBoundingClientRect();
            const offset = window.scrollY + rect.top - 100; // 100 пикселей выше
            window.scrollTo({ top: offset, behavior: 'smooth' });
          })
          .catch((error) => {
            console.error(error)
          });
      } catch (error) {
        console.error(error);
      } finally {
        this.isRegisterLoading = false
      }
    }
  },

  async mounted() {
    const ref = this.$route.query.ref

    if (!this.isTeacher && ref) {
      this.hasActiveRef = false

      try {
        await axios
          .get(
            `https://api.dictant.qt2.ru/auth/referal?ref=${ref}`,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
          .then(async (response) => {
            this.registerStudent.Region = response.data.Region
            this.registerStudentInitial.Region = response.data.Region

            this.registerStudent.City = response.data.City
            this.registerStudentInitial.City = response.data.City

            this.registerStudent.School = response.data.School
            this.registerStudentInitial.School = response.data.School

            if (this.registerStudent.Region) {
              this.hasActiveRef = true;
            }
          })
          .catch((error) => {
            console.error(error)
          });
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>