<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="About Us" />
        <DriveDigitalRevolution />
        <HistoryBeginsIn />
        <WhyChooseUs />
        <TheDataScience />
        <OurDataScientist class="pt-0" />
        <WhatOurClientsSaying />
        <Partner />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import DriveDigitalRevolution from '../AboutUsOne/DriveDigitalRevolution'
import HistoryBeginsIn from '../AboutUsOne/HistoryBeginsIn'
import WhyChooseUs from '../AboutUsOne/WhyChooseUs'
import TheDataScience from '../AboutUsOne/TheDataScience'
import OurDataScientist from '../Common/OurDataScientist'
import WhatOurClientsSaying from '../Common/WhatOurClientsSaying'
import Partner from '../AboutUsOne/Partner'
import Footer from '../Layout/Footer'

export default {
    name: 'AboutUsPageOne',
    components: {
        Navbar,
        PageTitle,
        DriveDigitalRevolution,
        HistoryBeginsIn,
        WhyChooseUs,
        TheDataScience,
        OurDataScientist,
        WhatOurClientsSaying,
        Partner,
        Footer,
    }
}
</script>
