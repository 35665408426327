<template>
    <div :class="['navbar-area', {'is-sticky': isSticky}]">
        <div class="parix-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <router-link class="navbar-brand" to="/">
                        <img src="../../assets/images/logo.png" alt="logo">
                    </router-link>

                    <div 
                        class="navbar-toggler"
                        @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                        v-bind:class="{ 'active': button_active_state }"
                        v-on:click="button_active_state = !button_active_state"
                    >
                        <span class="icon-bar top-bar"></span>
                        <span class="icon-bar middle-bar"></span>
                        <span class="icon-bar bottom-bar"></span>
                    </div>

                    <div class="collapse navbar-collapse" :class="{ show: active }">
                        <ul class="navbar-nav">
                            <li class="nav-item"><a href="#" class="nav-link">Home <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link to="/" class="nav-link">Data Science & ML Company</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/data-analytics-ai-startup" class="nav-link">Data Analytics & AI Startup</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/data-science-online-courses" class="nav-link">Data Science Online Courses</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/big-data-analysis-startup" class="nav-link">Big Data Analysis Startup</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/data-analytics-ml-consulting" class="nav-link">Data Analytics & ML Consulting</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/machine-learning-ai-solutions" class="nav-link">Machine Learning & AI Solutions</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/it-services" class="nav-link">IT Services</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/seo-agency" class="nav-link">SEO Agency</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/digital-marketing-agency" class="nav-link">Digital Marketing Agency</router-link>
                                    </li>

                                    <li class="nav-item"><a href="#" class="nav-link">Home Static Image <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/static-banner-image-one" class="nav-link">Data Science & ML Company</router-link>
                                            </li>
    
                                            <li class="nav-item">
                                                <router-link to="/static-banner-image-two" class="nav-link">Data Analytics & AI Startup</router-link>
                                            </li>
    
                                            <li class="nav-item">
                                                <router-link to="/static-banner-image-three" class="nav-link">Data Science Online Courses</router-link>
                                            </li>
    
                                            <li class="nav-item">
                                                <router-link to="/static-banner-image-four" class="nav-link">Big Data Analysis Startup</router-link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item"><a href="#" class="nav-link">About Us <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link to="/about-us-one" class="nav-link">About Us 1</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/about-us-two" class="nav-link">About Us 2</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/about-us-three" class="nav-link">About Us 3</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/about-us-four" class="nav-link">About Us 4</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/history" class="nav-link">History</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/testimonials" class="nav-link">Testimonials</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <a href="#" class="nav-link">Team <i class='bx bx-chevron-right'></i>
                                    </a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/team-one" class="nav-link">Team One</router-link>
                                            </li>
    
                                            <li class="nav-item">
                                                <router-link to="/team-two" class="nav-link">Team Two</router-link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item"><a href="#" class="nav-link">Services <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link to="/services-one" class="nav-link">Services One</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/services-two" class="nav-link">Services Two</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/services-three" class="nav-link">Services Three</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/services-four" class="nav-link">Services Four</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/services-five" class="nav-link">Services Five</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/services-six" class="nav-link">Services Six</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/services-seven" class="nav-link">Services Seven</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/services-eight" class="nav-link">Services Eight</router-link>
                                    </li>

                                    <li class="nav-item"><a href="#" class="nav-link">Services Details <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/services-details-one" class="nav-link">Default</router-link>
                                            </li>
    
                                            <li class="nav-item">
                                                <router-link to="/services-details-two" class="nav-link">With Image Slider</router-link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item"><a href="#" class="nav-link">Case Studies <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a href="#" class="nav-link">Portfolio <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/case-studies-one" class="nav-link">Case Studies 2 Columns</router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/case-studies-two" class="nav-link">Case Studies 3 Columns</router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/case-studies-three" class="nav-link">Case Studies 4 Columns</router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/case-studies-four" class="nav-link">Case Studies No Space</router-link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li class="nav-item"><a href="#" class="nav-link">Portfolio Details <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/case-studies-details-one" class="nav-link">Default</router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/case-studies-details-two" class="nav-link">With Video</router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/case-studies-details-three" class="nav-link">With Image Slider</router-link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item"><a href="#" class="nav-link">Pages <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link to="/faq" class="nav-link">FAQ</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/contact" class="nav-link">Contact</router-link>
                                    </li>

                                    <li class="nav-item"><a href="#" class="nav-link">Courses <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/courses-grid" class="nav-link">Courses Grid</router-link>
                                            </li>
    
                                            <li class="nav-item">
                                                <router-link to="/courses-right-sidebar" class="nav-link">Courses Right Sidebar</router-link>
                                            </li>
    
                                            <li class="nav-item">
                                                <router-link to="/courses-details" class="nav-link">Courses Details</router-link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li class="nav-item"><a href="#" class="nav-link">Events <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/events" class="nav-link">Events</router-link>
                                            </li>
    
                                            <li class="nav-item">
                                                <router-link to="/events-details" class="nav-link">Events Details</router-link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li class="nav-item"><a href="#" class="nav-link">Shop <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/products-one" class="nav-link">Products List 01</router-link>
                                            </li>
    
                                            <li class="nav-item">
                                                <router-link to="/products-two" class="nav-link">Products List 02</router-link>
                                            </li>
    
                                            <li class="nav-item">
                                                <router-link to="/cart" class="nav-link">Cart</router-link>
                                            </li>
    
                                            <li class="nav-item">
                                                <router-link to="/checkout" class="nav-link">Checkout</router-link>
                                            </li>
    
                                            <li class="nav-item">
                                                <router-link to="/products-details" class="nav-link">Products Details</router-link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/partner" class="nav-link">Partner</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/error-404" class="nav-link">404 Error</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/coming-soon" class="nav-link">Coming Soon</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/membership-levels" class="nav-link">Membership Levels</router-link>
                                    </li>
                                    
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">Authentication <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/profile-authentication" class="nav-link">Login/Register</router-link>
                                            </li>
                                            <li class="nav-item">
                                                <router-link to="/lost-your-password" class="nav-link">Forget Password</router-link>
                                            </li>
                                        </ul>
                                    </li>
    
                                    <li class="nav-item">
                                        <router-link to="/purchase-guide" class="nav-link">Purchase Guide</router-link>
                                    </li>
    
                                    <li class="nav-item">
                                        <router-link to="/privacy-policy" class="nav-link">Privacy Policy</router-link>
                                    </li>
    
                                    <li class="nav-item">
                                        <router-link to="/terms-of-service" class="nav-link">Terms of Service</router-link>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item"><a href="#" class="nav-link">Blog <i class='bx bx-chevron-down'></i></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link to="/blog-one" class="nav-link">Grid (2 in Row)</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/blog-two" class="nav-link">Grid (3 in Row)</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/blog-three" class="nav-link">Grid (Full Width)</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/blog-four" class="nav-link">Right Sidebar</router-link>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/blog-five" class="nav-link">Left Sidebar</router-link>
                                    </li>

                                    <li class="nav-item"><a href="#" class="nav-link">Single Post <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/blog-details-one" class="nav-link">Default</router-link>
                                            </li>
                                            
                                            <li class="nav-item">
                                                <router-link to="/blog-details-two" class="nav-link">With Video</router-link>
                                            </li>
                                            
                                            <li class="nav-item">
                                                <router-link to="/blog-details-three" class="nav-link">With Image Slider</router-link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <router-link to="/contact" class="default-btn"><i class="flaticon-right"></i>Get Started<span></span></router-link>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavbarStyleFour',
    data(){
        return {
            isSticky: false,
            active: false,
            button_active_state: false,
        }
    },

    mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 100){
                that.isSticky = true
            } else {
                that.isSticky = false
            }
        })
    }
}
</script>