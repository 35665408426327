<template>
    <div class="events-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-events-box">
                        <div class="image">
                            <router-link to="/events-details" class="d-block">
                                <img src="../../assets/images/events/events1.jpg" alt="image">
                            </router-link>
                            <span class="date">Wed, 20 May, 2020</span>
                        </div>

                        <div class="content">
                            <h3><router-link to="/events-details">Global Conference on Business Management</router-link></h3>
                            <span class="location"><i class="bx bx-map"></i>Vancover, Canada</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-events-box">
                        <div class="image">
                            <router-link to="/events-details" class="d-block">
                                <img src="../../assets/images/events/events2.jpg" alt="image">
                            </router-link>
                            <span class="date">Tue, 19 May, 2020</span>
                        </div>

                        <div class="content">
                            <h3><router-link to="/events-details">International Conference on Teacher Education</router-link></h3>
                            <span class="location"><i class="bx bx-map"></i>Sydney, Australia</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-events-box">
                        <div class="image">
                            <router-link to="/events-details" class="d-block">
                                <img src="../../assets/images/events/events3.jpg" alt="image">
                            </router-link>
                            <span class="date">Mon, 18 May, 2020</span>
                        </div>

                        <div class="content">
                            <h3><router-link to="/events-details">International Conference on Special Needs Education</router-link></h3>
                            <span class="location"><i class="bx bx-map"></i>Istanbul, Turkey</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-events-box">
                        <div class="image">
                            <router-link to="/events-details" class="d-block">
                                <img src="../../assets/images/events/events4.jpg" alt="image">
                            </router-link>
                            <span class="date">Sun, 17 May, 2020</span>
                        </div>

                        <div class="content">
                            <h3><router-link to="/events-details">International Conference on Literacy Teaching</router-link></h3>
                            <span class="location"><i class="bx bx-map"></i>Athens, Greece</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-events-box">
                        <div class="image">
                            <router-link to="/events-details" class="d-block">
                                <img src="../../assets/images/events/events5.jpg" alt="image">
                            </router-link>
                            <span class="date">Sat, 16 May, 2020</span>
                        </div>

                        <div class="content">
                            <h3><router-link to="/events-details">International Conference on Educational Administration</router-link></h3>
                            <span class="location"><i class="bx bx-map"></i>Rome, Italy</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-events-box">
                        <div class="image">
                            <router-link to="/events-details" class="d-block">
                                <img src="../../assets/images/events/events6.jpg" alt="image">
                            </router-link>
                            <span class="date">Fri, 15 May, 2020</span>
                        </div>

                        <div class="content">
                            <h3><router-link to="/events-details">International Conference on Education and Pedagogy</router-link></h3>
                            <span class="location"><i class="bx bx-map"></i>Athens, Greece</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-events-box">
                        <div class="image">
                            <router-link to="/events-details" class="d-block">
                                <img src="../../assets/images/events/events7.jpg" alt="image">
                            </router-link>
                            <span class="date">Thu, 14 May, 2020</span>
                        </div>

                        <div class="content">
                            <h3><router-link to="/events-details">Research Conference Aims and Objectives</router-link></h3>
                            <span class="location"><i class="bx bx-map"></i>Tokyo, Japan</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-events-box">
                        <div class="image">
                            <router-link to="/events-details" class="d-block">
                                <img src="../../assets/images/events/events8.jpg" alt="image">
                            </router-link>
                            <span class="date">Wed, 13 May, 2020</span>
                        </div>

                        <div class="content">
                            <h3><router-link to="/events-details">Conference on Gender Discrimination in Education</router-link></h3>
                            <span class="location"><i class="bx bx-map"></i>Oslo, Norway</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-events-box">
                        <div class="image">
                            <router-link to="/events-details" class="d-block">
                                <img src="../../assets/images/events/events9.jpg" alt="image">
                            </router-link>
                            <span class="date">Tue, 12 May, 2020</span>
                        </div>

                        <div class="content">
                            <h3><router-link to="/events-details">Quality and Improvement in Education Conference</router-link></h3>
                            <span class="location"><i class="bx bx-map"></i>Tokyo, Japan</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>   

<script>

export default {
    name: 'EventsContent'
}
</script>