<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Purchase Guide" />
        <PurchaseGuideContent />
        <Footer /> 
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import PurchaseGuideContent from '../PurchaseGuide/PurchaseGuideContent'
import Footer from '../Layout/Footer'

export default {
    name: 'PurchaseGuidePage',
    components: {
        Navbar,
        PageTitle,
        PurchaseGuideContent,
        Footer,
    }
}
</script>
