<template>
    <div class="about-area res-pt-0 pb-100 pt-70">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-img">
                        <img src="../../assets/images/about/about4.png" alt="image">
                        <div class="shape"><img src="../../assets/images/about/about-shape1.png"></div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <div class="content">
                            <h2>Data Science Training + Industry Experience</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                            <ul class="content-list">
                                <li><i class="flaticon-tick"></i> What is regularization and why it is useful</li>
                                <li><i class="flaticon-tick"></i> Perhaps the most popular data science methodologies</li>
                            </ul>
                            <p>You will learn about training data, and how to use a set of data to discover potentially predictive relationships. As you build the movie recommendation system, you will learn how to train algorithms using training data so you can predict the outcome for future datasets. You will also learn about overtraining and techniques to avoid it such as cross-validation. All of these skills are fundamental to machine learning.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                            <router-link to="/about-us-four" class="default-btn"><i class="flaticon-right"></i>More About Us<span></span></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'DataScienceTraining'
}
</script>