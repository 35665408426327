<template>
  <div>
    <Preloader v-if="isLoading" />
    <router-view v-slot="{ Component }" :global-data="globalData">
      <transition name="route" appear>
        <component :is="Component" />
      </transition>
    </router-view>

    <GoTop />
  </div>
</template>

<script>
import axios from "axios";
import Preloader from "./components/Layout/Preloader";
import GoTop from "./components/Layout/GoTop";

export default {
  name: "App",
  components: {
    Preloader,
    GoTop,
  },
  data() {
    return {
      isLoading: true,
      logo: null,
      globalData: null,
    };
  },
  methods: {
    async fetchGlobalData() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_STRAPI_URL}/api/global-data?populate=deep`,
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
            },
          }
        );

        if (response?.data?.data?.attributes) {
          this.globalData = response.data.data.attributes;
          this.logo = response.data.data.attributes.image;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    await this.fetchGlobalData();
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
};
</script>

<style>
.route-enter-from {
  opacity: 0;
}

.route-enter-active,
.route-leave-active {
  transition: all 0.2s;
  filter: blur(1rem);
}
</style>
