<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Forget Password" />
        <div class="profile-authentication-area ptb-100">
            <div class="container">
                <div class="login-form forget-password">
                    <form>
                        <div class="form-group">
                            <label>Username or email</label>
                            <input type="text" class="form-control" placeholder="Username or email">
                        </div>

                        <button type="submit">Forget password</button>
                    </form>
                </div>
            </div>
        </div>
        <Footer /> 
    </div>
</template>   

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import Footer from '../Layout/Footer'

export default {
    components: {
        Navbar,
        PageTitle,
        Footer,
    }
}
</script>