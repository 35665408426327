<template>
    <div>
        <Navbar />
        <MainBanner />
        <HowWeTakeYourBusiness />
        <WhyChooseUs />
        <WhatWeDo />
        <FunfactsTwo />
        <ServicesWeCanHelp />
        <WhatOurClientsSayingTwo />
        <IndustriesWeServe />
        <MeetOurData />
        <DataSciencePharmaceutical />
        <LatestValuableInsights class="bg-white" />
        <WeLikeToStartThree />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import MainBanner from '../StaticBannerImageFour/MainBanner'
import HowWeTakeYourBusiness from '../StaticBannerImageFour/HowWeTakeYourBusiness'
import WhyChooseUs from '../StaticBannerImageFour/WhyChooseUs'
import WhatWeDo from '../StaticBannerImageFour/WhatWeDo'
import FunfactsTwo from '../Common/FunfactsTwo'
import ServicesWeCanHelp from '../StaticBannerImageFour/ServicesWeCanHelp'
import WhatOurClientsSayingTwo from '../Common/WhatOurClientsSayingTwo'
import IndustriesWeServe from '../StaticBannerImageFour/IndustriesWeServe'
import MeetOurData from '../Common/MeetOurData'
import DataSciencePharmaceutical from '../Common/DataSciencePharmaceutical'
import LatestValuableInsights from '../StaticBannerImageFour/LatestValuableInsights'
import WeLikeToStartThree from '../Common/WeLikeToStartThree'
import Footer from '../Layout/Footer'

export default {
    name: 'StaticBannerImagePageFour',
    components: {
        Navbar,
        MainBanner,
        HowWeTakeYourBusiness,
        WhyChooseUs,
        WhatWeDo,
        FunfactsTwo,
        ServicesWeCanHelp,
        WhatOurClientsSayingTwo,
        IndustriesWeServe,
        MeetOurData,
        DataSciencePharmaceutical,
        LatestValuableInsights,
        WeLikeToStartThree,
        Footer,
    }
}
</script>
