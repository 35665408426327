<template>
    <div class="industries-serve-area bg-fafafb pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>Industries We Serve<span class="overlay"></span></h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
            </div>

            <div class="row">
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-industries-serve-box">
                        <div class="icon">
                            <i class="flaticon-factory"></i>
                        </div>
                        Manufacturing
                        <router-link to="/services-details-one" class="link-btn"></router-link>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-industries-serve-box">
                        <div class="icon">
                            <i class="flaticon-hospital"></i>
                        </div>
                        Healthcare
                        <router-link to="/services-details-one" class="link-btn"></router-link>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-industries-serve-box">
                        <div class="icon">
                            <i class="flaticon-tracking"></i>
                        </div>
                        Automobile
                        <router-link to="/services-details-one" class="link-btn"></router-link>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-industries-serve-box">
                        <div class="icon">
                            <i class="flaticon-investment"></i>
                        </div>
                        Banking
                        <router-link to="/services-details-one" class="link-btn"></router-link>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-industries-serve-box">
                        <div class="icon">
                            <i class="flaticon-house"></i>
                        </div>
                        Real Estate
                        <router-link to="/services-details-one" class="link-btn"></router-link>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-industries-serve-box">
                        <div class="icon">
                            <i class="flaticon-order"></i>
                        </div>
                        Logistics
                        <router-link to="/services-details-one" class="link-btn"></router-link>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-industries-serve-box">
                        <div class="icon">
                            <i class="flaticon-family-insurance"></i>
                        </div>
                        Insurance
                        <router-link to="/services-details-one" class="link-btn"></router-link>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-industries-serve-box">
                        <div class="icon">
                            <i class="flaticon-bitcoin"></i>
                        </div>
                        Capital Markets
                        <router-link to="/services-details-one" class="link-btn"></router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="vector-shape11"><img src="../../assets/images/shape/vector-shape11.png" alt="image"></div>
    </div>
</template>

<script>

export default {
    name: 'IndustriesWeServe'
}
</script>