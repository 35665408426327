<template>
    <div class="process-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-process-box">
                        <div class="number">1</div>
                        <div class="image">
                            <img src="../../assets/images/process/process1.png" alt="image">
                        </div>
                        <h3>Frame the Problem</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-process-box">
                        <div class="number">2</div>
                        <div class="image">
                            <img src="../../assets/images/process/process2.png" alt="image">
                        </div>
                        <h3>Collect the Raw Data</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-process-box">
                        <div class="number">3</div>
                        <div class="image">
                            <img src="../../assets/images/process/process3.png" alt="image">
                        </div>
                        <h3>Process the Data</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-process-box">
                        <div class="number">4</div>
                        <div class="image">
                            <img src="../../assets/images/process/process4.png" alt="image">
                        </div>
                        <h3>Explore the Data</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-process-box">
                        <div class="number">5</div>
                        <div class="image">
                            <img src="../../assets/images/process/process5.png" alt="image">
                        </div>
                        <h3>Perform In-depth Analysis</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-process-box">
                        <div class="number">6</div>
                        <div class="image">
                            <img src="../../assets/images/process/process6.png" alt="image">
                        </div>
                        <h3>Communicate Results</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="circle-shape1"><img src="../../assets/images/shape/circle-shape1.png" alt="image"></div>
    </div>
</template>

<script>

export default {
    name: 'ServicesContent'
}
</script>