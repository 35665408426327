<template>
    <div class="our-mission-area ptb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="our-mission-content">
                        <div class="content">
                            <h2>Real-world Data Challenges Prepare to Be A Data Science Leader</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                            <ul class="features-list">
                                <li><i class="flaticon-tick"></i> Leadership-Focused Learning</li>
                                <li><i class="flaticon-tick"></i> Experts at Teaching Online</li>
                                <li><i class="flaticon-tick"></i> Proven Career Outcomes</li>
                                <li><i class="flaticon-tick"></i> Diverse Backgrounds Welcome</li>
                                <li><i class="flaticon-tick"></i> The Basics of Machine Learning</li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="our-mission-image">
                        <img src="../../assets/images/our-mission/our-mission2.png" alt="image">
                        <div class="shape"><img src="../../assets/images/our-mission/our-mission-shape1.png"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'RealWorldData'
}
</script>