<template>
    <div>
        <NavbarStyleThree />
        <PageTitle pageTitle="Testimonials" />
        <WhatOurClientsSayingOne />
        <WhatOurClientsSayingTwo />
        <Partner />
        <Footer class="bg-white" />
    </div>
</template>

<script>
import NavbarStyleThree from '../Layout/NavbarStyleThree'
import PageTitle from '../Common/PageTitle'
import WhatOurClientsSayingOne from '../Testimonials/WhatOurClientsSayingOne'
import WhatOurClientsSayingTwo from '../Testimonials/WhatOurClientsSayingTwo'
import Footer from '../Layout/Footer'

export default {
    name: 'TestimonialsPage',
    components: {
        NavbarStyleThree,
        PageTitle,
        WhatOurClientsSayingOne,
        WhatOurClientsSayingTwo,
        Footer,
    }
}
</script>
