<template>
    <div class="pricing-area pt-100 pb-70 bg-f4f7fe">
        <div class="container">
            <div class="section-title">
                <h2>Our Affordable Pricing Plans<span class="overlay"></span></h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-pricing-box">
                        <div class="pricing-header">
                            <h3>Weekly Plan</h3>
                        </div>

                        <div class="price">
                            <sup>$</sup> 120 <sub>/ Per week</sub>
                        </div>

                        <ul class="pricing-features">
                            <li><i class='bx bxs-badge-check'></i>SEO & Branding</li>
                            <li><i class='bx bxs-badge-check'></i>Digital Marketing</li>
                            <li><i class='bx bxs-badge-check'></i>Google Analytics</li>
                            <li><i class='bx bxs-badge-check'></i>Branding Solutions</li>
                            <li><i class='bx bxs-badge-check'></i>Digital Accounts</li>
                            <li><i class='bx bxs-x-circle red'></i>Pay-per-Click</li>
                            <li> <i class='bx bxs-x-circle red'></i>24/7 Support</li>
                        </ul>

                        <div class="btn-box">
                            <a class="default-btn" href="#"><i class="flaticon-tick"></i>Select the Plan<span></span></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-pricing-box">
                        <div class="pricing-header">
                            <h3>Month Plan</h3>
                        </div>

                        <div class="price">
                            <sup>$</sup> 840 <sub>/ Per month</sub>
                        </div>

                        <ul class="pricing-features">
                            <li><i class='bx bxs-badge-check'></i>SEO & Branding</li>
                            <li><i class='bx bxs-badge-check'></i>Digital Marketing</li>
                            <li><i class='bx bxs-badge-check'></i>Google Analytics</li>
                            <li><i class='bx bxs-badge-check'></i>Branding Solutions</li>
                            <li><i class='bx bxs-badge-check'></i>Digital Accounts</li>
                            <li><i class='bx bxs-badge-check'></i>Pay-per-Click</li>
                            <li> <i class='bx bxs-x-circle red'></i>24/7 Support</li>
                        </ul>

                        <div class="btn-box">
                            <a class="default-btn" href="#"><i class="flaticon-tick"></i>Select the Plan<span></span></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div class="single-pricing-box">
                        <div class="pricing-header">
                            <h3>Yearly Plan</h3>
                        </div>

                        <div class="price">
                            <sup>$</sup> 3,600 <sub> / Per yearly</sub>
                        </div>

                        <ul class="pricing-features">
                            <li><i class='bx bxs-badge-check'></i>SEO & Branding</li>
                            <li><i class='bx bxs-badge-check'></i>Digital Marketing</li>
                            <li><i class='bx bxs-badge-check'></i>Google Analytics</li>
                            <li><i class='bx bxs-badge-check'></i>Branding Solutions</li>
                            <li><i class='bx bxs-badge-check'></i>Digital Accounts</li>
                            <li><i class='bx bxs-badge-check'></i>Pay-per-Click</li>
                            <li> <i class='bx bxs-badge-check'></i>24/7 Support</li>
                        </ul>

                        <div class="btn-box">
                            <a class="default-btn" href="#"><i class="flaticon-tick"></i>Select the Plan<span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'OurAffordablePricingPlans'
}
</script>