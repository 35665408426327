<template>
    <div class="scientist-area pt-100 pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-12">
                    <div class="scientist-box-list">
                        <div class="row">
                            <div class="col-lg-6 col-sm-6 col-md-6 offset-lg-1">
                                <div class="single-scientist-item">
                                    <img src="../../assets/images/scientist/scientist9.jpg" alt="image">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6 col-md-6">
                                <div class="single-scientist-item">
                                    <img src="../../assets/images/scientist/scientist10.jpg" alt="image">
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-12 col-md-6 offset-lg-0 offset-md-3">
                                <div class="single-scientist-item">
                                    <img src="../../assets/images/scientist/scientist11.jpg" alt="image">
                                </div>
                            </div>
                        </div>

                        <div class="map-shape1"><img src="../../assets/images/shape/map-shape1.png" alt="image"></div>
                        <div class="vector-shape5"><img src="../../assets/images/shape/vector-shape5.png" alt="image"></div>
                    </div>
                </div>

                <div class="col-lg-5 col-md-12">
                    <div class="scientist-section-title">
                        <span class="sub-title"><img src="../../assets/images/star-icon.png" alt="image"> Our Team</span>
                        <h2>Meet Our Data Scientist Preparing For Your Business Success</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                        <router-link to="/team-one" class="default-btn"><i class="flaticon-view"></i>View Our Team<span></span></router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="vector-shape4"><img src="../../assets/images/shape/vector-shape4.png" alt="image"></div>
    </div>
</template>

<script>

export default {
    name: 'MeetOurData'
}
</script>