<template>
    <div>
        <div class="about-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="about-image-box">
                            <img src="../../assets/images/about/about5.jpg" alt="image">
                            
                            <div class="box">
                                <div 
                                    class="video-btn"
                                    v-on:click="isPopupMethod(isPopup)"
                                    style="cursor: pointer"
                                >
                                    <i class="flaticon-google-play"></i> 
                                    Watch Video
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="about-content">
                            <div class="content">
                                <span class="sub-title"><img src="../../assets/images/star-icon.png" alt="image"> About Us</span>
                                <h2>Drive Digital Revolution Through Data Science</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                                <ul class="features-list">
                                    <li>
                                        <span>
                                            <div class="icon">
                                                <img src="../../assets/images/icon1.png" alt="image">
                                            </div>
                                            <h3>10 Years</h3>
                                            <p>On the market</p>
                                        </span>
                                    </li>

                                    <li>
                                        <span>
                                            <div class="icon">
                                                <img src="../../assets/images/icon2.png" alt="image">
                                            </div>
                                            <h3>45+</h3>
                                            <p>Team members</p>
                                        </span>
                                    </li>

                                    <li>
                                        <span>
                                            <div class="icon">
                                                <img src="../../assets/images/icon1.png" alt="image">
                                            </div>
                                            <h3>100%</h3>
                                            <p>Satisfaction rate</p>
                                        </span>
                                    </li>

                                    <li>
                                        <span>
                                            <div class="icon">
                                                <img src="../../assets/images/icon1.png" alt="image">
                                            </div>
                                            <h3>80%</h3>
                                            <p>Senior scientist</p>
                                        </span>
                                    </li>
                                </ul>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <AboutInnerContent />
            </div>

            <div class="circle-shape1"><img src="../../assets/images/shape/circle-shape1.png" alt="image"></div>
        </div>
        
        <div 
            class="popup-video show" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AboutInnerContent from '../AboutUsOne/AboutInnerContent'

export default {
    name: 'DriveDigitalRevolution',
    components: { 
        AboutInnerContent
    },
    data (){
        return {
            isPopup: false
        }
    },
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
    },
}
</script>