<template>
    <div>
        <NavbarStyleThree />
        <PageTitle pageTitle="Case Studies no Space" />
        <CaseStudiesContent />
        <Footer />
    </div>
</template>

<script>
import NavbarStyleThree from '../Layout/NavbarStyleThree'
import PageTitle from '../Common/PageTitle'
import CaseStudiesContent from '../CaseStudiesFour/CaseStudiesContent'
import Footer from '../Layout/Footer'

export default {
    name: 'CaseStudiesPageFour',
    components: {
        NavbarStyleThree,
        PageTitle,
        CaseStudiesContent,
        Footer,
    }
}
</script>
