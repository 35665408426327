<template>
    <div>
        <NavbarStyleThree />
        <PageTitle pageTitle="Services" />
        <ServicesContent />
        <WeLikeToStartThree />
        <Footer />
    </div>
</template>

<script>
import NavbarStyleThree from '../Layout/NavbarStyleThree'
import PageTitle from '../Common/PageTitle'
import ServicesContent from '../ServicesEight/ServicesContent'
import WeLikeToStartThree from '../Common/WeLikeToStartThree'
import Footer from '../Layout/Footer'

export default {
    name: 'ServicesPageEight',
    components: {
        NavbarStyleThree,
        PageTitle,
        ServicesContent,
        WeLikeToStartThree,
        Footer,
    }
}
</script>
