<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="About Us" />
        <DataScienceTraining />
        <RealWorldData />
        <Funfacts />
        <HistoryBeginsIn />
        <BuildYourDataScience />
        <CheckFullSchedule />
        <WhatOurClientsSaying />
        <Partner />
        <Footer class="bg-white" />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import DataScienceTraining from '../AboutUsThree/DataScienceTraining'
import RealWorldData from '../AboutUsThree/RealWorldData'
import Funfacts from '../AboutUsThree/Funfacts'
import HistoryBeginsIn from '../AboutUsThree/HistoryBeginsIn'
import BuildYourDataScience from '../AboutUsThree/BuildYourDataScience'
import CheckFullSchedule from '../Common/CheckFullSchedule'
import WhatOurClientsSaying from '../AboutUsThree/WhatOurClientsSaying'
import Partner from '../AboutUsThree/Partner'
import Footer from '../Layout/Footer'

export default {
    name: 'AboutUsPageThree',
    components: {
        Navbar,
        PageTitle,
        DataScienceTraining,
        RealWorldData,
        Funfacts,
        HistoryBeginsIn,
        BuildYourDataScience,
        CheckFullSchedule,
        WhatOurClientsSaying,
        Partner,
        Footer,
    }
}
</script>
