<template>
    <div class="main-banner-area">
        <div class="container">
            <div class="banner-area-content">
                <h1>Big Data, AI & ML Consulting!</h1>
                <p>Get all kinds of hi-tech decesion making consulting services using Artificial Intelligence and Machine Learning from one umbrella.</p>
                <router-link to="/contact" class="default-btn"><i class="flaticon-web"></i>Work With Us<span></span></router-link>
                <img src="../../assets/images/main-banner/banner-five-main-pic1.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'MainBanner'
}
</script>