<template>
     <div class="solutions-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-solutions-box">
                        <div class="icon">
                            <i class="flaticon-rocket"></i>
                        </div>
                        <h3><router-link to="/services-details-one">Startup Applications</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>
                        <router-link to="/services-details-one" class="view-details-btn">View Details</router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-solutions-box">
                        <div class="icon">
                            <i class="flaticon-laptop"></i>
                        </div>
                        <h3><router-link to="/services-details-one">SaaS Solutions</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>
                        <router-link to="/services-details-one" class="view-details-btn">View Details</router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-solutions-box">
                        <div class="icon">
                            <i class="flaticon-money"></i>
                        </div>
                        <h3><router-link to="/services-details-one">E-Commerce Platforms</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>
                        <router-link to="/services-details-one" class="view-details-btn">View Details</router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-solutions-box">
                        <div class="icon">
                            <i class="flaticon-segmentation"></i>
                        </div>
                        <h3><router-link to="/services-details-one">Research</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>
                        <router-link to="/services-details-one" class="view-details-btn">View Details</router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-solutions-box">
                        <div class="icon">
                            <i class="flaticon-analytics"></i>
                        </div>
                        <h3><router-link to="/services-details-one">Analytics</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>
                        <router-link to="/services-details-one" class="view-details-btn">View Details</router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-solutions-box">
                        <div class="icon">
                            <i class="flaticon-settings"></i>
                        </div>
                        <h3><router-link to="/services-details-one">Technology</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>
                        <router-link to="/services-details-one" class="view-details-btn">View Details</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ServicesContent'
}
</script>