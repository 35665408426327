<template>
    <section class="about-area pb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-image">
                        <img src="../../assets/images/about/about7.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <div class="content">
                            <span class="sub-title"><img src="../../assets/images/star-icon.png" alt="image"> About Us</span>
                            <h2>We Are Dynamic Team Of SEO Agency</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                            <ul class="about-list">
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        Big Data
                                        <img src="../../assets/images/our-mission/our-mission-shape2.png" alt="image">
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        Data Visualization
                                        <img src="../../assets/images/our-mission/our-mission-shape2.png" alt="image">
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        Data Warehousing
                                        <img src="../../assets/images/our-mission/our-mission-shape2.png" alt="image">
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        Data Management
                                        <img src="../../assets/images/our-mission/our-mission-shape2.png" alt="image">
                                    </span>
                                </li>
                            </ul>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                            <router-link to="/about-us-one" class="default-btn"><i class="flaticon-right"></i>More About Us<span></span></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="circle-shape1"><img src="../../assets/images/shape/circle-shape1.png" alt="image"></div>
    </section>
</template>

<script>

export default {
    name: 'WeAreDynamic'
}
</script>