<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Products Details" />
        <ProductsDetailsContent />
        <RelatedProducts />
        <Footer /> 
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import ProductsDetailsContent from '../ProductsDetails/ProductsDetailsContent'
import RelatedProducts from '../ProductsDetails/RelatedProducts'
import Footer from '../Layout/Footer'

export default {
    name: 'ProductsDetailsPage',
    components: {
        Navbar,
        PageTitle,
        ProductsDetailsContent,
        RelatedProducts,
        Footer,
    }
}
</script>
