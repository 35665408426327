<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Membership Levels" />
        <MembershipLevelsContent />
        <Footer /> 
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import MembershipLevelsContent from '../MembershipLevels/MembershipLevelsContent'
import Footer from '../Layout/Footer'

export default {
    name: 'MembershipLevelsPage',
    components: {
        Navbar,
        PageTitle,
        MembershipLevelsContent,
        Footer,
    }
}
</script>
