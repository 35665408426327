<template>
  <div>
    <div class="banner-section">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-5 col-md-12">
            <div class="banner-content">
              <h1>Data Analytics Consulting Services</h1>
              <p>
                World leading reliable research & AI based Data Analytics
                solutions for Big Data companies and consumer business products.
                Drive your success without stress!
              </p>
              <div class="btn-box">
                <a href="#" class="default-btn"
                  ><i class="flaticon-right"></i>About Us<span></span
                ></a>
                <div
                  class="video-btn"
                  v-on:click="isPopupMethod(isPopup)"
                  style="cursor: pointer"
                >
                  <i class="flaticon-google-play"></i>
                  Watch Video
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-7 col-md-12">
            <div class="banner-animation-image">
              <img
                src="../../assets/images/main-banner/banner-two/banner-two1.png"
                alt="image"
              />
              <img
                src="../../assets/images/main-banner/banner-two/banner-two2.png"
                alt="image"
              />
              <img
                src="../../assets/images/main-banner/banner-two/banner-two3.png"
                alt="image"
              />
              <img
                src="../../assets/images/main-banner/banner-two/banner-two4.png"
                alt="image"
              />
              <img
                src="../../assets/images/main-banner/banner-two/banner-two5.png"
                alt="image"
              />
              <img
                src="../../assets/images/main-banner/banner-two/banner-two6.png"
                alt="image"
              />
              <img
                src="../../assets/images/main-banner/banner-two/banner-two7.png"
                alt="image"
              />
              <img
                src="../../assets/images/main-banner/banner-two/banner-two8.png"
                alt="image"
              />
              <img
                src="../../assets/images/main-banner/banner-two/banner-two9.png"
                alt="image"
              />
              <img
                src="../../assets/images/main-banner/banner-two/banner-two10.png"
                alt="image"
              />
              <img
                src="../../assets/images/main-banner/banner-two/banner-two11.png"
                alt="image"
              />
              <img
                src="../../assets/images/main-banner/banner-two/banner-two12.png"
                alt="image"
              />
              <img
                src="../../assets/images/main-banner/banner-two/banner-two-main-pic.png"
                alt="image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="popup-video show" v-if="isPopup">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe
              src="https://www.youtube.com/embed/bk7McNUjWgw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainBanner",
  data() {
    return {
      isPopup: false,
    };
  },
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
};
</script>