<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Products List" />
        <ProductsContent />
        <Footer /> 
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import ProductsContent from '../ProductsOne/ProductsContent'
import Footer from '../Layout/Footer'

export default {
    name: 'ProductsPageOne',
    components: {
        Navbar,
        PageTitle,
        ProductsContent,
        Footer,
    }
}
</script>
