<template>
    <div class="services-details-info">
        <ul class="services-list">
            <li><router-link to="/services-details-one" class="main">AI & ML Development</router-link></li>
            <li><router-link to="/services-details-one">Data Analytics</router-link></li>
            <li><router-link to="/services-details-one">Data Science</router-link></li>
            <li><router-link to="/services-details-one">Artificial Intelligence</router-link></li>
            <li><router-link to="/services-details-one">Data Visualization</router-link></li>
        </ul>

        <div class="download-file">
            <h3>Brochures</h3>

            <ul>
                <li><a href="#">PDF Download <i class='bx bxs-file-pdf'></i></a></li>
                <li><a href="#">Services Details.txt <i class='bx bxs-file-txt'></i></a></li>
            </ul>
        </div>

        <div class="services-contact-info">
            <h3>Contact Info</h3>
            
            <ul>
                <li>
                    <div class="icon">
                        <i class='bx bx-user-pin'></i>
                    </div>
                    <span>Phone:</span>
                    <a href="tel:+21453545413" target="_blank">+2145 354 5413</a>
                </li>
                <li>
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <span>Location:</span>
                    New York, USA
                </li>
                <li>
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <span>Email:</span>
                    <a href="mailto:hello@parix.com">hello@parix.com</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ServicesSidebar'
}
</script>