<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="History" />
        <HistoryBeginsInOne />
        <HistoryBeginsInTwo />
        <Partner />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import HistoryBeginsInOne from '../History/HistoryBeginsInOne'
import HistoryBeginsInTwo from '../History/HistoryBeginsInTwo'
import Footer from '../Layout/Footer'

export default {
    name: 'HistoryPage',
    components: {
        Navbar,
        PageTitle,
        HistoryBeginsInOne,
        HistoryBeginsInTwo,
        Footer,
    }
}
</script>
