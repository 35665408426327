<template>
    <div>
        <NavbarStyleThree />
        <PageTitle pageTitle="Blog Left Sidebar" />
        <BlogContent />
        <Footer /> 
    </div>
</template>

<script>
import NavbarStyleThree from '../Layout/NavbarStyleThree'
import PageTitle from '../Common/PageTitle'
import BlogContent from '../BlogFive/BlogContent'
import Footer from '../Layout/Footer'

export default {
    name: 'BlogPageFive',
    components: {
        NavbarStyleThree,
        PageTitle,
        BlogContent,
        Footer,
    }
}
</script>
