<template>
    <div class="services-area pt-100 pb-70 bg-f1f8fb">
        <div class="container">
            <div class="section-title">
                <span class="sub-title"><img src="../../assets/images/star-icon.png" alt="image"> Our Features</span>
                <h2>We’re Here To Help<span class="overlay"></span></h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-item-box">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon1.png" alt="image">
                        </div>
                        <h3>Incredible Infrastructure</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-item-box">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon2.png" alt="image">
                        </div>
                        <h3>Email Notifications</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-item-box">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon3.png" alt="image">
                        </div>
                        <h3>Simple Dashboard</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-item-box">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon4.png" alt="image">
                        </div>
                        <h3>Information Retrieval</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-item-box">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon5.png" alt="image">
                        </div>
                        <h3>Drag and Drop</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-item-box">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon6.png" alt="image">
                        </div>
                        <h3>Deadline Reminders</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'WereHereToHelp'
}
</script>