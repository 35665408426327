<template>
    <div>
        <ComingSoonContent />
    </div>
</template>

<script>
import ComingSoonContent from '../ComingSoon/ComingSoonContent'

export default {
    name: 'ComingSoonPage',
    components: {
        ComingSoonContent,
    }
}
</script>