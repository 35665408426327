<template>
  <div class="preloader">
    <div class="loader">
      <div class="sbl-half-circle-spin">
        <div></div>
      </div>
    </div>
  </div>
</template>   

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Preloader",
};
</script>