<template>
    <div class="about-area pb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-img">
                        <img src="../../assets/images/about/about6.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <div class="content">
                            <span class="sub-title"><img src="../../assets/images/star-icon.png" alt="image"> Services</span>
                            <h2>Cloud Hosting Services</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                            <ul class="about-list mb-0">
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        Cloud Databases
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        Website Hosting
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        File Storage
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        Forex Trading
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        File Backups
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        Remote Desktop
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        Email Servers
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        Hybrid Cloud
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CloudHostingServices'
}
</script>