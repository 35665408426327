<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Blog Grid (Full Width)" />
        <BlogContent />
        <Footer /> 
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import BlogContent from '../BlogThree/BlogContent'
import Footer from '../Layout/Footer'

export default {
    name: 'BlogPageThree',
    components: {
        Navbar,
        PageTitle,
        BlogContent,
        Footer,
    }
}
</script>
