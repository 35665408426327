<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="About Us" />
        <BusinessAnalytics class="pt-100" />
        <DataScience />
        <Funfacts />
        <OurDataAnalytics />
        <HistoryBeginsIn />
        <MeetOurData />
        <WhatOurClientsSaying />
        <Partner />
        <WeLikeToStart />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import BusinessAnalytics from '../Common/BusinessAnalytics'
import DataScience from '../Common/DataScience'
import Funfacts from '../Common/Funfacts'
import OurDataAnalytics from '../AboutUsTwo/OurDataAnalytics'
import HistoryBeginsIn from '../AboutUsTwo/HistoryBeginsIn'
import MeetOurData from '../Common/MeetOurData'
import WhatOurClientsSaying from '../AboutUsTwo/WhatOurClientsSaying'
import Partner from '../AboutUsTwo/Partner'
import WeLikeToStart from '../AboutUsTwo/WeLikeToStart'
import Footer from '../Layout/Footer'

export default {
    name: 'AboutUsPageTwo',
    components: {
        Navbar,
        PageTitle,
        BusinessAnalytics,
        DataScience,
        Funfacts,
        OurDataAnalytics,
        HistoryBeginsIn,
        MeetOurData,
        WhatOurClientsSaying,
        Partner,
        WeLikeToStart,
        Footer,
    }
}
</script>
