<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Checkout" />
        <CheckoutContent />
        <Footer /> 
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import CheckoutContent from '../Checkout/CheckoutContent'
import Footer from '../Layout/Footer'

export default {
    name: 'CheckoutPage',
    components: {
        Navbar,
        PageTitle,
        CheckoutContent,
        Footer,
    }
}
</script>
