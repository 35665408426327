<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Partner" />
        <PartnerContent />
        <WhatOurClientsSaying class="ptb-100" />
        <PartnerTwo />
        <Footer /> 
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import PartnerContent from  '../Partner/PartnerContent'
import WhatOurClientsSaying from '../Common/WhatOurClientsSaying'
import PartnerTwo from '../Common/PartnerTwo'
import Footer from '../Layout/Footer'

export default {
    name: 'PartnerPage',
    components: {
        Navbar,
        PageTitle,
        PartnerContent,
        WhatOurClientsSaying,
        PartnerTwo,
        Footer,
    }
}
</script>
