<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Events Details" />
        <EventsDetailsContent />
        <Footer /> 
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import EventsDetailsContent from '../EventsDetails/EventsDetailsContent'
import Footer from '../Layout/Footer'

export default {
    name: 'EventsDetailsPage',
    components: {
        Navbar,
        PageTitle,
        EventsDetailsContent,
        Footer,
    }
}
</script>
